
import Curso from "@/interfaces/Curso";
import router from "@/router";
import { ref, defineComponent } from "vue";

export default defineComponent({
  setup(props, { emit }) {
    const urlImg = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const show = ref(false);
    const loading = ref<boolean>(false);
    const curso = ref<Curso>();
    const aulasTotal = ref<number>(0);
    const aulasAssistida = ref<number>(0);

    const open = (dataCurso: Curso): void => {
      curso.value = dataCurso;
      show.value = true;
    };

    const contarAssuntos = (curso: Curso): string => {
      let count = 0;
      curso.disciplinas.forEach((disciplina) => {
        disciplina.assuntos.forEach(() => {
          count++;
        });
      });

      if (count != 1) {
        return `${count} assuntos`;
      }
      return `1 assunto`;
    };

    const contarAulas = (curso: Curso): string => {
      let count = 0;
      curso.disciplinas.forEach((disciplina) => {
        disciplina.assuntos.forEach((assunto) => {
          assunto.aulas.forEach(() => {
            count++;
          });
        });
      });

      if (count != 1) {
        return `${count} aulas`;
      }
      return `1 aula`;
    };

    const porcetangem = (curso: Curso): number[] => {
      let aulas = 0;
      let assistidas = 0;
      let porcentagem = 0;
      let decimal = 0;
      curso.disciplinas.forEach((disciplina) => {
        disciplina.assuntos.forEach((assunto) => {
          assunto.aulas.forEach((aula) => {
            if (new Date().getTime() > new Date(aula.data_exibicao).getTime()) {
              if (aula.aula_assistida) {
                assistidas++;
              }
              aulas++;
            }
          });
        });
      });
      porcentagem = (assistidas * 100) / aulas;
      decimal = porcentagem / 100;
      aulasTotal.value = aulas;
      aulasAssistida.value = assistidas;
      return [decimal, porcentagem];
    };

    const verAula = (id: string): void => {
      router.push(`/ver-aula/${id}`);
    };

    return {
      show,
      open,
      loading,
      curso,
      urlImg,
      verAula,
      contarAssuntos,
      contarAulas,
      porcetangem,
      aulasTotal,
      aulasAssistida,
    };
  },
});
